@import url(https://fonts.googleapis.com/css?family=Raleway:900);
@font-face {
  font-family: "Questa Regular";
  src: url('./Questa-Regular.eot');
  src: url('./Questa-Regular.eot?#iefix') format("embedded-opentype"), url('./Questa-Regular.svg#Questa-Regular') format("svg"), url('./Questa-Regular.ttf') format("truetype"), url('./Questa-Regular.woff') format("woff"), url('./Questa-Regular.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Crimson-Roman";
  src: url('./Crimson-Roman.eot');
  src: url('./Crimson-Roman.eot?#iefix') format("embedded-opentype"), url('./Crimson-Roman.svg#Crimson-Roman') format("svg"), url('./Crimson-Roman.ttf') format("truetype"), url('./Crimson-Roman.woff') format("woff"), url('./Crimson-Roman.woff2') format("woff2");
  font-weight: normal;
  font-style: normal;
}
 