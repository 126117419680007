/** * At the start of the animation the dot * has an opacity of .2 */
@keyframes blink {
	/** * At 20% the dot is fully visible and * then fades out slowly */
	/** * Until it reaches an opacity of .2 and * the animation can start again */
	 0% {
		 opacity: 0.2;
	}
	 20% {
		 opacity: 1;
	}
	 100% {
		 opacity: 0.2;
	}
}
 .saving span {
	 animation-name: blink;
	 animation-duration: 1.4s;
	 animation-iteration-count: infinite;
	/** * This makes sure that the starting style (opacity: .2) * of the animation is applied before the animation starts. * Otherwise we would see a short flash or would have * to set the default styling of the dots to the same * as the animation. Same applies for the ending styles. */
	 animation-fill-mode: both;
}
 .saving span:nth-child(2) {
	 animation-delay: 0.2s;
}
 .saving span:nth-child(3) {
	 animation-delay: 0.4s;
}
 