@import url(https://fonts.googleapis.com/css?family=Raleway:900);
body, html, #root {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body, html {
  scroll-behavior: smooth;
}

/** * At the start of the animation the dot * has an opacity of .2 */
@keyframes blink {
	/** * At 20% the dot is fully visible and * then fades out slowly */
	/** * Until it reaches an opacity of .2 and * the animation can start again */
	 0% {
		 opacity: 0.2;
	}
	 20% {
		 opacity: 1;
	}
	 100% {
		 opacity: 0.2;
	}
}
 .saving span {
	 animation-name: blink;
	 animation-duration: 1.4s;
	 animation-iteration-count: infinite;
	/** * This makes sure that the starting style (opacity: .2) * of the animation is applied before the animation starts. * Otherwise we would see a short flash or would have * to set the default styling of the dots to the same * as the animation. Same applies for the ending styles. */
	 animation-fill-mode: both;
}
 .saving span:nth-child(2) {
	 animation-delay: 0.2s;
}
 .saving span:nth-child(3) {
	 animation-delay: 0.4s;
}
 
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Questa Regular";
  src: url(/static/media/Questa-Regular.77407b18.eot);
  src: url(/static/media/Questa-Regular.77407b18.eot?#iefix) format("embedded-opentype"), url(/static/media/Questa-Regular.f94f954c.svg#Questa-Regular) format("svg"), url(/static/media/Questa-Regular.45a20356.ttf) format("truetype"), url(/static/media/Questa-Regular.b058ab04.woff) format("woff"), url(/static/media/Questa-Regular.8b8a2a5e.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Crimson-Roman";
  src: url(/static/media/Crimson-Roman.704d56d5.eot);
  src: url(/static/media/Crimson-Roman.704d56d5.eot?#iefix) format("embedded-opentype"), url(/static/media/Crimson-Roman.299ce5fb.svg#Crimson-Roman) format("svg"), url(/static/media/Crimson-Roman.76207179.ttf) format("truetype"), url(/static/media/Crimson-Roman.6057a702.woff) format("woff"), url(/static/media/Crimson-Roman.1178b7dc.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
}
 
